
export const setIsConnectedRoom = (state, status) => {
  state.isConnectedRoom = status;
};
export const setCurrentOrderId = (state, orderId) => {
  state.currentOrderId = orderId;
};
export const setChats = (state, chats) => {
  state.chats = chats;
};
export const setSelectedChat = (state, chatName) => {
  state.selectedChat = chatName;
};
export const setLoadingChatState = (state, loadingChatState) => {
  state.loadingChatState = loadingChatState;
};
export const addChat = (state, {chat_name, chat} ) => {
  state.chats[chat_name] = chat
};
export const updateChat = (state, {chat_name, message} ) => {
  state.chats[chat_name].last_message_text = message.text;
  state.chats[chat_name].last_message_date_create = message.date_create;
  state.chats[chat_name].unread_messages = state.chats[chat_name].unread_messages + 1;
};
export const setMessages = (state, {chat_name, messages} ) => {
  state.chats[chat_name].messages = messages
};
export const setLastReadMessage = (state, {chat_name, last_read_message_id} ) => {
  state.chats[chat_name].last_read_message = last_read_message_id;
  state.chats[chat_name].unread_messages = 0;
};
export const initializeChatsState = (state, loadState ) => {
  state.initializeChatsState = loadState;
};

