// users/actions.js

// Импорт функции обработчика сообщений
import { handleMessages } from './handlers';
import orderAPI from "@/service/API/orderAPI";
import { addMessages} from "@/utils/orderChat";

// Экспорт действия для обработки сообщений WebSocket
export const handleChatMessages = ({ commit,rootGetters,state }, message) => {
  handleMessages(commit,rootGetters , message,state);
};

export const joinOrderRoom = async ({commit, dispatch}, orderId) => {
    commit('setCurrentOrderId', orderId);
    dispatch('websocket/sendMessage', {
        scope_type: 'order',
        action: 'join_order',
        order_id: orderId
    }, {root: true}); // Указываем, что действие находится в корневом уровне
    commit('setIsConnectedRoom', true); // Обновляем флаг подключения к комнате
};

export const loadOrderChats= async ({commit}, orderId) => {
    commit('initializeChatsState', false);
    const response = await orderAPI.getChats(orderId)
    commit('setChats', response.data);
    commit('initializeChatsState', true);

};

export const leaveOrderRoom = ({ commit, dispatch }) => {
    dispatch('websocket/sendMessage', {
      scope_type: 'order',
      action: 'leave_order'
    }, { root: true }); // Действие находится в корневом уровне
    commit('setCurrentOrderId', null); // Сбрасываем текущий ID заказа
    commit('setIsConnectedRoom', false);
    commit('setSelectedChat', null)
    commit('setChats', {});
    commit('setLoadingChatState', false);
    commit('initializeChatsState', false);
};

export const sendChatMessage = ({ dispatch },data) => {
    dispatch('websocket/sendMessage', {
        scope_type: 'order_chat',
        action: "send_message",
        message: data
      }, { root: true }); // Действие находится в корневом уровне
};
export const editChatMessage = ({ dispatch },data) => {
    dispatch('websocket/sendMessage', {
        scope_type: 'order_chat',
        action: "edit_message",
        data: data,

      }, { root: true }); // Действие находится в корневом уровне
};
export const updateLastReadMessage = ({  dispatch }, lastReadMessage)=> {
    dispatch('websocket/sendMessage', {
      scope_type: 'order_chat',
      action: "update_last_read_message",
      last_read_message: lastReadMessage.id,
    }, { root: true }); // Действие находится в корневом уровне
};

export const removeMessage = ({  dispatch }, messageId)=> {

    dispatch('websocket/sendMessage', {
      scope_type: 'order_chat',
      action: "remove_message",
      remove_message: messageId,
    }, { root: true }); // Действие находится в корневом уровне
};

export const fetchMessages = async ({state,rootGetters,commit}) => {
    const orderId = state.currentOrderId;
    const chat_name = state.selectedChat;
    try {
        const response = await orderAPI.getMessages(orderId, {chat_type: chat_name});
        const userId = rootGetters['auth/user'].id;

        const messages = addMessages([], response.data, userId)
        commit('setMessages', {chat_name, messages})
    } catch (error) {
      console.error('Failed to fetch messages:', error);
    }
};

export const changeSelectedChat = async ({state,rootGetters,commit},chatName) => {
    const chat = state.chats[chatName];
    commit('setSelectedChat', chatName);

    if (chat && !chat.messages) {
        commit('setLoadingChatState', true);
        await fetchMessages({state,rootGetters,commit});
        commit('setLoadingChatState', false);
    }
};
