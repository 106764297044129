export function handleMessages(commit, data) {
    switch (data.type) {

        case 'unread_messages_for_user': {
            commit('setUnreadMessageList', data.data.unread_messages);
            break;
        }
        case 'personal_message': {
            console.log('personal_message',data.message);
            commit('setNotification', data.message);
            break;
        }
    }
}