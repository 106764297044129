<script setup>
// import moment from 'moment'

// eslint-disable-next-line no-undef
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {useWebSocket} from "@/composables/useWebSocket";
import {computed, ref} from "vue";
import {useOrderChat} from "@/composables/useOrderChat";
import ReplyMessageCard from "@/components/OrderPage/Chats/ReplyMessageCard.vue";
const {removeMessage,currentOrderId} = useOrderChat();

// eslint-disable-next-line no-undef
const props = defineProps({
  message:Object,
  selectedMessage:{
    type:Boolean,
    default:false
  },
  isPersonalChat:{
    type:Boolean,
    default:false
  },
  baseUrl:String
})

// eslint-disable-next-line no-undef
const emit = defineEmits(['editMessage','replyMessage','goToReply'])
const {allUsers} = useWebSocket()

const itemsMyMessage = ref([
    {
        label: 'Ответить',
        show: true,
        icon: 'pi pi-reply',
        command: () => {
                    emit('replyMessage', props.message)
                }

    },
    {
        label: 'Редактировать',
        show:props.message.from_me === true,
        icon: 'pi pi-pencil',
        command: () => {
                    emit('editMessage', props.message)
                }

    },
    {
        label: 'Удалить',
        show:props.message.from_me === true,
        icon: 'pi pi-trash',
        command: () => {
            removeMessage(props.message.id)
        }
    }
]);
const menu = ref();
const items = ref(null);


const contextmenuShow = (event) => {
  if (currentOrderId.value) {
    items.value = itemsMyMessage.value.filter(item => item.show === true)
    menu.value.show(event)
  }
}
const formatMessageWithEmojiSize = (message, size='30px') => {
  return message.replace(/([\uD800-\uDBFF][\uDC00-\uDFFF])/g, `<span style="font-size: ${size};">$1</span>`);
};
const creatorName = computed(() => {
  return allUsers.value[props.message.creator]?.name || ''
})
const recipientsByTooltip = computed(() => {
  return props.message.recipient
    .map((person) => allUsers.value[person]?.name || '')
    .join('\n')
})
</script>

<template>
  <ContextMenu ref="menu" :model="items"  >
  </ContextMenu>
  <div v-if="message.isDateHeader" :data-id="message.id" class="date-header flex justify-center">
    <div class="w-1/3 py-1  rounded-md bg-blue-200 text-center text-nowrap text-red-600 text-xl"
         v-text="message.dateCreate || 'Дата отсутствует'"
    />
  </div>
<div v-else
     class="w-full animate-flip-up animate-once animate-duration-[1000ms] animate-ease-in-out animate-normal animate-fill-forwards"
>
  <div v-if="!message.from_me && !message.resultMessage && !isPersonalChat" class="text-left text-red-400 text-xs "
       v-text="creatorName"
  />

<div class="flex w-full"
     :id="`message-${message.id}`"
     :class="{
         'justify-end': message.from_me === true,
         'justify-start': message.from_me === false,
         'justify-center': message.from_me === undefined
     }">

  <div :class="{'w-36':message.from_me!==false}" class="flex"></div>
  <div class="flex flex-col whitespace-pre-wrap rounded-md p-2 m-1 message "

     @contextmenu="message.status !== 'deleted' && contextmenuShow($event)"
       :class="{
         'bg-yellow-100':selectedMessage,
         'bg-green-100': message.from_me === true,
         'bg-purple-100': message.from_me === false,
         'bg-gray-100': message.from_me === undefined
       }"
  >
        <ReplyMessageCard v-if="message.reply_message" :replyMessage="message.reply_message"
                          @goToReply="emit('goToReply', message.reply_message.id)"
 />

    <Image v-if="message.files.length && message.status!=='deleted'"
           alt="Image" preview class="px-1 h-[100px] bg-white flex flex-row justify-center"
            width="100"
           :src="message.files[0].file.startsWith(baseUrl)?message.files[0].file:baseUrl+message.files[0].file"
           :pt="{
                 rotateRightButton: { class: 'bg-primary-200' },
                 rotateLeftButton: { class: 'bg-primary-200' },
                 zoomOutButton: { class: 'bg-yellow-200' },
                 zoomInButton: { class: 'bg-yellow-200' },
                 closeButton: { class: 'bg-red-200' },
                 toolbar: { class: 'gap-x-1.5' },
           }"
    />
    <div v-if="message.status!=='deleted'">
      <div class="relative -top-1" v-if="message.from_me === false"
        v-tooltip="{ value: recipientsByTooltip }"    >
        <font-awesome-icon :icon="(message.recipient.length===1)?'fa-solid fa-user':'fa-solid fa-user-group' "
                           class="absolute -top-2 right-1"
                           :class="{'text-red-600': message.for_me}"
                           v-if="message.recipient.length"
        />
      </div>
      <div class="relative -top-1" v-else
        v-tooltip.left="{ value: recipientsByTooltip }"    >
        <font-awesome-icon :icon="(message.recipient.length===1)?'fa-solid fa-user':'fa-solid fa-user-group' "
                           class="absolute -top-2 right-1"
                           :class="{'text-red-600': message.for_me}"
                           v-if="message.recipient.length"
        />
      </div>
    </div>
    <div class="flex flex-row justify-end " >
      <div :class="{'text-red-600':message.status==='deleted'}" class="flex  message-text break-all"
           style="word-wrap: break-word;"
           v-html="formatMessageWithEmojiSize(message.status==='deleted'?'Сообщение удалено':message.text)"
      />

      <div class="relative flex text-nowrap place-self-end text-xs text-gray-400 top-2 gap-3 "
            v-if="message.from_me!==undefined"
      >
        <span v-if="message.status==='edited'" v-text="message.status==='edited'?'Изменено':''" class="text-red-500"/>
        <span v-text="new Date(message.date_create).toLocaleTimeString('ru-RU',{hour: '2-digit', minute:'2-digit'})"/>

      </div>
    </div>
  </div>
  <div :class="{'w-36':message.from_me!==true}"></div>
  </div>

</div>
</template>

<style scoped>
.date-header {
  position: relative;
}

.date-header::before,
.date-header::after {
  content: '';
  position: absolute;
  top: 50%;
  width: calc(100%/3);
  height: 3px; /* Высота разделителя */
  //background: linear-gradient(to right, #1c20fa, #1c20fa, transparent);
}


.date-header::before {
  left: 0;
  background: linear-gradient(to right, transparent, #1c20fa, #1c20fa);
}

.date-header::after {
  right: 0;
    background: linear-gradient(to right, #1c20fa, #1c20fa, transparent);

}
@keyframes fadeOut {
  0% { background-color: #2832fa; }
  50% { background-color: #2832fa; }
  100% { background-color: inherit; }
}

.fade-out {
  animation: fadeOut 5s forwards;
}
</style>