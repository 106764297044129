 // handlers.js

import {addMessage, addMessages, updateMessage,} from "@/utils/orderChat";

export function handleMessages(commit, rootGetters, data) {
    const chat_name = data.chat_type
    // Обработка сообщений чата
    switch (data.action) {
        case 'add_message': {
            const userId = rootGetters['auth/user'].id;
            const chats = rootGetters['orderChat/chats'];
            const message = data.message
            if (!chats[chat_name]){
                let interlocutor
                let chat_type
                if (/^\d+$/.test(chat_name)) {
                    interlocutor = parseInt(chat_name);
                    chat_type = 'personal';
                } else {
                    interlocutor = null;
                    chat_type = chat_name;
                }
                const chat = {
                      id: message.chat,
                      interlocutor: interlocutor,
                      last_message_date_create: message.date_create,
                      last_message_text: message.text,
                      last_read_message_id: null,
                      chat_type: chat_type,//
                      unread_messages: 1                    ,
                }
                commit('addChat', {chat_name, chat})
                const messages = addMessages([], [message], userId)
                commit('setMessages', {chat_name, messages})
            }else{
                const oldMessages = chats[chat_name]['messages']
                if (oldMessages !== undefined){
                    const messages = addMessage(oldMessages, message, userId)
                    commit('setMessages', {chat_name, messages})
                }
                commit('updateChat', {chat_name, message})
            }
            break;
        }
        case 'update_last_read_message': {
            const last_read_message_id = data.last_read_message_id;
            commit('setLastReadMessage', {chat_name, last_read_message_id});
            break;
        }
        case 'update_message': {
            const chats = rootGetters['orderChat/chats'];
            const message = data.message
            const messages = updateMessage(chats[chat_name]['messages'], message)
            commit('setMessages', {chat_name, messages})
            break;
        }
    }
}