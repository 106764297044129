export const setUnreadEmailsCount = (state, count) => {
  state.unreadEmailsCount = count;
};

export const setUnreadMessageList = (state,unreadMessageList ) => {
  state.unreadMessageList = unreadMessageList;
};
export const setNotification = (state,notification ) => {
  state.notification = notification;
};

export const removeNotifications = (state ) => {
  state.notifications = null;
};




