// users/actions.js

// Импорт функции обработчика сообщений
import { handleMessages } from './handlers';

// Экспорт действия для обработки сообщений WebSocket
export const handleNotificationsMessages = ({ commit }, message) => {
  handleMessages(commit, message);
};
// Экспорт действия для обработки сообщений WebSocket
export const removeNotifications = ({ commit }) => {
  commit('removeNotifications');
};


