// composables/useGlobalChat.js
import { useStore } from 'vuex';
import {computed} from "vue";

export function useGlobalChat() {
  const store = useStore();

  const sendChatMessage = async (message) => {
    await store.dispatch('globalChat/sendChatMessage', message);
  };
  const editChatMessage = async (data) => {
    await store.dispatch('globalChat/editChatMessage', data);
  };

  const joinOrderRoom = async (orderId) => {
    await store.dispatch('globalChat/joinOrderRoom', orderId);

  };
  const loadGlobalChats = async () => {
    await store.dispatch('globalChat/loadGlobalChats');

  };
  const leaveOrderRoom = async () => {
    await store.dispatch('globalChat/leaveOrderRoom');
  };
  const updateLastReadMessage = async (lastReadMessage) => {
    await store.dispatch('globalChat/updateLastReadMessage',lastReadMessage);
  };
  const removeMessage = async (messageId) => {
    await store.dispatch('globalChat/removeMessage',messageId);
  };

  const setSelectedChat = async (chatName) => {
    console.log('setSelectedChat',chatName);
    await store.dispatch('globalChat/changeSelectedChat', chatName);
  };
  const currentOrderId = computed(() => store.getters['globalChat/currentOrderId']);
  const unreadMessagesCount = computed(() => store.getters['globalChat/unreadMessagesCount']);
  const initializeChatsState = computed(() => store.getters['globalChat/initializeChatsState']);
  const loadingChatState = computed(() => store.getters['globalChat/loadingChatState']);
  const chats = computed(() => store.getters['globalChat/chats']);
  const selectedChat = computed(() => store.getters['globalChat/selectedChat']);
  const messagesList = computed(() => {
      const chat = chats.value[selectedChat.value];
      if (chat && chat.messages) {
        return chat.messages;
      } else {
        return [];
      }
  });

  return {
    sendChatMessage,
    editChatMessage,
    joinOrderRoom,
    loadGlobalChats,
    leaveOrderRoom,
    updateLastReadMessage,
    removeMessage,
    setSelectedChat,
    unreadMessagesCount,
    loadingChatState,
    messagesList,
    selectedChat,
    chats,
    initializeChatsState,
    currentOrderId
  };
}

