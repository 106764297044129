import API from "@/service/API/API";
const url_pref = '/api/v1'
export default {
    getEvents(){
        console.log('getEvents')
        return API().get(`${url_pref}/order-event/`)
    },
    getEventsTypeList(){
        console.log('getEventsTypeList')
        return API().get(`${url_pref}/event-type/`)
    },
    deleteEvent(eventId){
        console.log('deleteEvent')
        return API().delete(`${url_pref}/order-event/${eventId}/`)
    },
    saveEvent(data){
        if (data.id) {
            // Если у события есть идентификатор, выполняем запрос на обновление (PATCH)
            return API().patch(`${url_pref}/order-event/${data.id}/`, data)
                   .then(response =>  response )
                   .catch(error => { throw error.response });
        } else {
            // Если у события нет идентификатора, выполняем запрос на добавление (POST)
            return API().post(`${url_pref}/order-event/`, data)
                .then(response =>  response )
                .catch(error => { throw error.response });
        }
    },
    saveEventType(data){
        if (data.id) {
            // Если у события есть идентификатор, выполняем запрос на обновление (PATCH)
            return API().patch(`${url_pref}/event-type/${data.id}/`, data)
                   .then(response =>  response )
                   .catch(error => { throw error.response });
        } else {
            // Если у события нет идентификатора, выполняем запрос на добавление (POST)
            return API().post(`${url_pref}/event-type/`, data)
                .then(response =>  response )
                .catch(error => { throw error.response });
        }
    },


}