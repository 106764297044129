// store/index.js
import { createStore } from 'vuex';
import auth from './modules/auth';
import order from "@/store/modules/order";
import orderStatus from "@/store/modules/orderStatus";
import {websocket} from "@/store/modules/websocket";
import {users} from "@/store/modules/users";
import {notifications} from "@/store/modules/notifications";
import {orderChat} from "@/store/modules/orderChat";
import {globalChat} from "@/store/modules/globalChat";
import orderHistory from "@/store/modules/orderHistory";
import orderContracts from "@/store/modules/orderContracts";
import orderFiles from "@/store/modules/orderFiles";
import orderCompany from "@/store/modules/orderCompany";
import contractStatus from "@/store/modules/contractStatus";
import weekends from "@/store/modules/weekends";
import groupCompany from "@/store/modules/groupCompany";
import baseProducts from "@/store/modules/baseProducts";
import persons from "@/store/modules/persons";
import fields from "@/store/modules/fields";
import orderEvents from "@/store/modules/orderEvents";
import eventsType from "@/store/modules/eventsType";

const store = createStore({
  modules: {
    auth,
    order,
    orderCompany,
    orderHistory,
    orderStatus,
    orderEvents,
    eventsType,
    weekends,
    groupCompany,
    baseProducts,
    contractStatus,
    orderContracts,
    orderFiles,
    websocket,
    orderChat,
    globalChat,
    fields,
    users,
    notifications,
    persons
  },
  // Дополнительные опции хранилища, если они вам нужны
});

export default store;