// composables/useOrderChat.js
import { useStore } from 'vuex';
import {computed} from "vue";

export function useOrderChat() {
  const store = useStore();

  const sendChatMessage = async (message) => {
    await store.dispatch('orderChat/sendChatMessage', message);
  };
  const editChatMessage = async (data) => {
    await store.dispatch('orderChat/editChatMessage', data);
  };

  const joinOrderRoom = async (orderId) => {
    await store.dispatch('orderChat/joinOrderRoom', orderId);

  };
  const loadOrderChats = async (orderId) => {
    await store.dispatch('orderChat/loadOrderChats', orderId);

  };
  const leaveOrderRoom = async () => {
    await store.dispatch('orderChat/leaveOrderRoom');
  };
  const updateLastReadMessage = async (lastReadMessage) => {
    await store.dispatch('orderChat/updateLastReadMessage',lastReadMessage);
  };
  const removeMessage = async (messageId) => {
    await store.dispatch('orderChat/removeMessage',messageId);
  };

  const setSelectedChat = async (chatName) => {
    console.log('setSelectedChat',chatName);
    await store.dispatch('orderChat/changeSelectedChat', chatName);
  };
  const currentOrderId = computed(() => store.getters['orderChat/currentOrderId']);
  const unreadMessagesCount = computed(() => store.getters['orderChat/unreadMessagesCount']);
  const initializeChatsState = computed(() => store.getters['orderChat/initializeChatsState']);
  const loadingChatState = computed(() => store.getters['orderChat/loadingChatState']);
  const chats = computed(() => store.getters['orderChat/chats']);
  const selectedChat = computed(() => store.getters['orderChat/selectedChat']);
  const messagesList = computed(() => {
      const chat = chats.value[selectedChat.value];
      if (chat && chat.messages) {
        return chat.messages;
      } else {
        return [];
      }
  });

  return {
    sendChatMessage,
    editChatMessage,
    joinOrderRoom,
    loadOrderChats,
    leaveOrderRoom,
    updateLastReadMessage,
    removeMessage,
    setSelectedChat,
    unreadMessagesCount,
    loadingChatState,
    messagesList,
    selectedChat,
    chats,
    initializeChatsState,
    currentOrderId
  };
}

