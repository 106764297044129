
<script setup>
import {onMounted, ref, watch} from "vue";
const items = ref([
    { disabled: false ,page:"Dashboard",label: 'Главная', icon: 'pi pi-home' },
    { disabled: false ,page:"CompaniesList",label: 'Компании', icon: 'pi pi-building' },
    { disabled: false ,page:"OrdersList",label: 'Заказы', icon: 'pi pi-briefcase' },
    { disabled: false ,page:"PersonsList",label: 'Сотрудники', icon: 'pi pi-user' },
    { disabled: false ,page:"Statistic",label: 'Статистика', icon: 'pi pi-chart-bar' },
    { disabled: false ,page:"Calendar",label: 'Календарь', icon: 'pi pi-calendar' },
    { disabled: false ,page:"PayersList",label: 'Контрагенты', icon: 'pi pi-money-bill' },
    { disabled: false ,page:"ContractsList",label: 'Контракты', icon: 'pi pi-table' },
    { disabled: false ,page:"Products",label: 'Товары', icon: 'pi pi-box' },
    { disabled: false ,page:"Mail",label: 'Почта', icon: 'pi pi-envelope' },
    { disabled: false ,page:"Settings",label: 'Настройки', icon: 'pi pi-cog' },
]);
import { useToast } from "primevue/usetoast";
const toast = useToast();

const visible = ref(false)

const logOut = async () => {
  try {
    await logout; // вызов действия logout из Vuex store
    await router.push({name: 'Login'}); // перенаправление на страницу входа
  } catch (error) {
    console.error('Error during logout:', error);
  }
};
const { initializeWebSocket,unreadEmailsCount,notification,removeNotification } = useWebSocket();
watch(notification, (value) => {
  if (value &&
      router.currentRoute.value.params.orderId !== value.order.toString() &&
      router.currentRoute.value.name !== 'Dashboard'
  ) {
    console.log('notification:',value);
    value.from_me = false;
    value.for_me = true;
    toast.add({
      severity: 'warn',
      summary: "Личное сообщение",
      group:'notification',
      data: value
    });
    removeNotification(); // удаление уведомления
  }
})
onMounted(async () => {
  await fetchOrderStatusList();
  await fetchContractStatusList();
  await fetchPersonsList();
  await fetchWeekendsList();
  await fetchGkList();
  await fetchBaseProductsList();
  await fetchEventsTypeList();
  await initializeWebSocket();
  await loadGlobalChats()
  let permission = Notification.permission;
    if (permission !== "granted") {
      await Notification.requestPermission();
    }
})

import image from "@/assets/Namotka.png"
import avatar from "@/assets/avatar.jpg"
import router from "@/router";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {useUser} from "@/composables/useUser";
import {useOrderStatus} from "@/composables/useOrderStatus";
import {useContractStatus} from "@/composables/useContractStatus";
import {useAuth} from "@/composables/useAuth";
import {useWebSocket} from "@/composables/useWebSocket";
import {useWeekends} from "@/composables/useWeekends";
import {useGroupCompany} from "@/composables/useGroupCompany";
import {useBaseProducts} from "@/composables/useBaseProducts";
import {usePersons} from "@/composables/usePersons";
import {useEventsType} from "@/composables/useEventsType";
import CustomToast from "@/components/Main/CustomToast.vue";
import {useGlobalChat} from "@/composables/useGlobalChat";
const { logout } = useAuth();
const { userName } = useUser();
const {  fetchOrderStatusList } = useOrderStatus();
const {  fetchContractStatusList } = useContractStatus();
const {  fetchWeekendsList } = useWeekends();
const {  fetchGkList } = useGroupCompany();
const {  fetchBaseProductsList } = useBaseProducts();
const { fetchPersonsList } = usePersons()
const { fetchEventsTypeList } = useEventsType()
const { loadGlobalChats } = useGlobalChat();


</script>



<template>
  <Sidebar v-model:visible="visible"
           :modal="false"
           :pt="{
             root:{class:['w-1/6 ']}
           }"
  >
    <template #container>

        <div class="size-full flex flex-col justify-between p-0 rounded-r-2xl" style="background: linear-gradient(0deg, #1e1a3080, #000);">
          <div class="absolute z-50 w-0 h-screen right-0 opacity-75 hover:text-blue-900 flex flex-col  justify-center" >
            <div class=" min-h-18 py-4 w-6  rounded-e-2xl  flex flex-col border-2 border-l-0 border-black justify-center "
                 style="background: linear-gradient(270deg, rgb(189,189,189), #1a1a1a);"
                 @click="visible = false" >
              <font-awesome-icon icon="ellipsis-vertical" size="2x" />
            </div>
          </div>

          <div id="nav-header" class="flex flex-row justify-center p-4 rounded-r-2xl" style="background: linear-gradient(0deg, #221d1dd9, blue);">
            <Image :src="image" alt="Image" width="75"
                   class="animate-rotate-y animate-infinite animate-duration-[4000ms] animate-delay-[500ms] animate-ease-in"
            />

          </div>

          <div id="nav-content" class="flex flex-col px-2">
            <div class="flex flex-row px-2"
                 v-for="(item, index) in items"
                 :key="index"

            >

              <Button class="animate-jump-in py-1 w-full animate-once animate-duration-1000 animate-delay-[100ms] animate-ease-linear"
                      text
                      size="small"
                      severity="info"
                      :disabled="item.disabled"
                      :label="item.label"
                      :icon="item.icon"
                      :badge="item.page === 'Mail' && unreadEmailsCount?unreadEmailsCount+'+':''"
                      badgeSeverity="info"
                      @click="$router.push({name:item.page})"
              />
            </div>
          </div>

          <div id="nav-footer" class="p-1">

            <div class="flex flex-row justify-between items-center p-2 bg-gray-200  hover:bg-gray-300  rounded-md">
              <Avatar :image="avatar" class="mr-2" shape="circle" />
              <div v-text="userName"/>
              <div
                   @click="logOut"
                   v-tooltip="{
                     value: 'Выход из системы',
                     pt: {
                         text: {style: 'text-red-500'}
                     }
                   }"
              >

              <font-awesome-icon icon="right-from-bracket"  size="2x" class="text-blue-400  hover:text-blue-600"

              />
                </div>
            </div>


          </div>
          </div>
    </template>
  </Sidebar>
  <CustomToast />
  <div class="fixed z-50 w-0 h-screen bg-primary-400  opacity-25  hover:text-blue-900 hover:opacity-75 flex flex-col  justify-center">
    <div class=" min-h-18 py-4 w-6 rounded-e-2xl flex flex-col border-2 border-l-0 border-black justify-center"
         style="background: linear-gradient(270deg, rgb(189,189,189), #1a1a1a);"
         @click="visible = true" >
      <font-awesome-icon icon="ellipsis-vertical" size="2x" />
    </div>
  </div>
</template>


